import React, { Component } from "react";
// import Slider from "react-slick";
// import testimonialData from "../../../data/Testimonials/home-two";
// import quote from '../../../assets/img/icons/quote.png'
import SectionTitle from "../../UI/SectionTitle";

const NextArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-long-arrow-right" />
    </button>
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-long-arrow-left" />
    </button>
  );
};

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const testSettings = {
      slidesToShow: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      className: "testimonial-content--2",
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    return (
      <div className="testimonial-area testimonial-area--2 bg-offwhite sm-top">
        <div className="container">
          <div className="row d-lg-none">
            <div className="col-12 text-center">
              <SectionTitle
                title="Culpa officia deserunt mollit animd laborum"
                heading="Innovative Approach"
                // tagline="More over <span class='tag-no'>1900+</span><strong>customer</strong>"
              />
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-md-5">
              {/* <Slider
                asNavFor={this.state.nav2}
                ref={(slider) => (this.slider1 = slider)}
                arrows={false}
                className={`testimonial-thumbnail mt-sm-5 mt-md-1`}
              >
                {testimonialData.map((testimonial) => (
                  <div key={testimonial.id}>
                    <div className="testimonial-thumbnail-item">
                      <img
                        src={require("../../../assets/img/" +
                          testimonial.authorThumb)}
                        alt="Businex-Testimonial"
                      />
                    </div>
                  </div>
                ))}
              </Slider> */}
              <div className="testimonial-thumbnail-item">
                <img
                  src={require("../../../assets/img/Aboutheader.jpg")}
                  alt="Businex-Testimonial"
                />
              </div>
            </div>

            <div className="col-md-7 ml-auto">
              <div className="testimonial-area-right">
                <div className="d-none d-lg-block">
                  <SectionTitle
                    title="Innovative Approach"
                    heading="Technological Innovation in the Industry Law"
                    // tagline="More over <span class='tag-no'>1900+</span><strong>customer</strong>"
                  />
                </div>

                <div className="testimonial-content-wrap pl-0">
                  {/* <Slider
                                        asNavFor={this.state.nav1}
                                        ref={slider => (this.slider2 = slider)}
                                        {...testSettings}
                                    >
                                        {
                                            testimonialData.map(testiItem => (
                                                <div key={testiItem.id}>
                                                    <div className="testimonial-item testimonial-item--2">
                                                        <div className="testimonial-txt">
                                                            <img src={quote} alt="Businex"/>
                                                            <h2>{testiItem.quote}</h2>
                                                            <p className="client-name">{testiItem.author}, <span
                                                                className="designation">{testiItem.designation}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Slider> */}
                  <div className="rustedAdvisor mb-3">
                    <h4>Trusted advisors for successful people</h4>
                    <p style={{ fontStyle: "oblique", fontSize: "small" }}>
                      Miventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo nemo enim consequuntur magni dolores eos.
                    </p>
                  </div>
                  <div className="rustedAdvisor mb-3">
                    <h4>Our talks with people are confidential</h4>
                    <p style={{ fontStyle: "oblique", fontSize: "small" }}>
                      Miventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo nemo enim consequuntur magni dolores eos.
                    </p>
                  </div>
                  <div className="rustedAdvisor mb-3">
                    <h4>Legal & regulatory developments that impact</h4>
                    <p style={{ fontStyle: "oblique", fontSize: "small" }}>
                      Miventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo nemo enim consequuntur magni dolores eos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
