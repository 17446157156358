import React from 'react'

const InputAra = () => {
  return (
    <div>
       <h2>ABOUT US</h2>
         <p>القانون عبارة عن مجموعة من القواعد التي يتم إنشاؤها وإنفاذها من قبل المؤسسات الاجتماعية أو الحكومية لتنظيم السلوك</p>
    </div>
  )
}

export default InputAra