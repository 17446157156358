import React from 'react'

const Input = () => {
  return (
    <div>
       <h2>ABOUT US</h2>
         <p>Law is a set of rules that are created and are enforceable by social or governmental institutions to regulate behavior</p>
    </div>
  )
}

export default Input
